import { useState, useEffect } from 'react';
import moment from 'moment';
import { message, Form } from 'antd';

import { FormField } from '@components';

import { useDebouncedCallback, defaultDebounceTime } from '@hooks';

import { getOptions, requiredRule } from '@utils';

const getConstructionTestOptions = (tableOptions: any, entry_type?: string) => {
  let options = [];
  if (entry_type === 'zkouska_v_terenu') {
    options = tableOptions.construction_test;
  } else if (entry_type === 'odber_vzorku') {
    options = tableOptions.sampling_type;
  }
  return getOptions(options);
}

const getConstructionPartOptions = (tableOptions: any, construction_test?: string) => {
  let options = [];
  if (construction_test) {
    options = tableOptions.construction_part.filter((option: any) => {
      return (
        !option.construction_tests ||
        option.construction_tests.length === 0 ||
        option.construction_tests.indexOf(
          parseInt(construction_test, 10)
        ) > -1
      );
    });
  }
  options = options.map((option: any) => ({
    ...option,
    name: option.select_name || option.name,
  }));
  return getOptions(options);
}

const getTechnologyPartOptions = (tableOptions: any, construction_part?: number) => {
  let options = [];
  if (construction_part) {
    options = tableOptions.technology_part.filter((option: any) => {
      return option.parent === construction_part;
    });
  }
  return getOptions(options);
}

const EditOrderForm = ({
  initialValues,
  onFormSubmit,
  simple,
  tableOptions,
}: any) => {
  const [form] = Form.useForm();

  const [constructionTestOptions, setConstructionTestOptions] = useState([]);
  const [constructionPartOptions, setConstructionPartOptions] = useState([]);
  const [technologyPartOptions, setTechnologyPartOptions] = useState([]);

  const performUpdate = async () => {
    try {
      await form.validateFields();

      const fieldValues = form.getFieldsValue();

      // Merge realization date and time values
      const realization_date = moment(fieldValues.realization_date);
      const realization_time = moment(fieldValues.realization_time);

      realization_date.set({
        hour: realization_time.hour(),
        minute: realization_time.minute(),
        second: realization_time.second(),
      });

      const payload = {
        ...fieldValues,
        technology_part: fieldValues.technology_part,
        realization_date: realization_date,
        realization_time: realization_date,
      };

      onFormSubmit(payload);
    } catch (error) {
      console.error(error);
      message.error('Opravte prosím chyby ve formuláři');
    }
  }

  const performDebouncedUpdate = useDebouncedCallback(() => {
    performUpdate();
  }, defaultDebounceTime);

  const calculateConstructionTestOptions = () => {
    setConstructionTestOptions(
      getConstructionTestOptions(
        tableOptions,
        form.getFieldValue('entry_type'),
      )
    );
  }

  const calculateConstructionPartOptions = () => {
    setConstructionPartOptions(
      getConstructionPartOptions(
        tableOptions,
        form.getFieldValue('construction_test'),
      )
    );
  }

  const calculateTechnologyPartOptions = () => {
    setTechnologyPartOptions(
      getTechnologyPartOptions(
        tableOptions,
        form.getFieldValue('construction_part')
      )
    );
  }

  useEffect(() => {
    calculateConstructionTestOptions();
    calculateConstructionPartOptions();
    calculateTechnologyPartOptions();
  }, []);

  return (
    <div>
      <Form
        form={form}
        autoComplete="off"
        initialValues={{
          laboratory: tableOptions?.laboratory?.[0]?.id,
          ...initialValues,
        }}
        onValuesChange={(changedFields) => {
          if ('entry_type' in changedFields) {
            form.setFieldsValue({ construction_test: null });
            calculateConstructionTestOptions();
          }

          if ('construction_test' in changedFields) {
            form.setFieldsValue({ construction_part: null });
            calculateConstructionPartOptions();
          }

          if ('construction_part' in changedFields) {
            form.setFieldsValue({ technology_part: null });
            calculateTechnologyPartOptions();
          }

          performDebouncedUpdate();
        }}
      >
        <div className="row4">
          <Form.Item
            className="form-item"
            name="object_label"
            rules={[requiredRule]}
          >
            <FormField
              type="select"
              placeholder="* Stavební objekt"
              dropdownMatchSelectWidth={true}
              options={getOptions(tableOptions.object_label)}
            />
          </Form.Item>
          <Form.Item
            className="form-item"
            name="stationing"
            rules={!simple ? [requiredRule] : []}
          >
            <FormField
              type="text"
              placeholder="* Staničení"
            />
          </Form.Item>
          <Form.Item
            className="form-item"
            name="entry_type"
            rules={!simple ? [requiredRule] : []}
          >
            <FormField
              type="select"
              placeholder="* Druh činnosti"
              options={[{
                label: 'Zkouška v terénu',
                value: 'zkouska_v_terenu',
              }, {
                label: 'Odběr vzorku',
                value: 'odber_vzorku',
              }]}
            />
          </Form.Item>
          <Form.Item
            className="form-item"
            name="construction_test"
            rules={!simple ? [requiredRule] : []}
          >
            <FormField
              type="select"
              placeholder="* Typ zkoušky"
              options={constructionTestOptions}
            />
          </Form.Item>
        </div>
        <div className="row4">
          <Form.Item
            className="form-item"
            name="count"
            rules={!simple ? [requiredRule] : []}
          >
            <FormField
              type="text"
              placeholder="* Počet"
            />
          </Form.Item>
          <div className="nested-row2">
            <Form.Item
              className="form-item"
              name="acreage_value"
              rules={!simple ? [requiredRule] : []}
            >
              <FormField
                type="text"
                placeholder="* Výměra"
              />
            </Form.Item>
            <Form.Item
              className="form-item"
              name="acreage_unit"
              rules={!simple ? [requiredRule] : []}
            >
              <FormField
                type="select"
                placeholder="* Jednotky"
                options={[
                  { label: 'm²', value: 'm2' },
                  { label: 'm³', value: 'm3' },
                  { label: 'bm', value: 'bm' },
                  { label: 't', value: 't' },
                ]}
              />
            </Form.Item>
          </div>
          <Form.Item
            className="form-item"
            name="construction_part"
            rules={!simple ? [requiredRule] : []}
          >
            <FormField
              type="select"
              placeholder="* Technologický celek"
              // dropdownMatchSelectWidth={true}
              options={constructionPartOptions}
            />
          </Form.Item>
          <Form.Item
            className="form-item"
            name="technology_part"
          >
            <FormField
              type="select"
              placeholder="Část technologického celku"
              options={technologyPartOptions}
            />
          </Form.Item>
        </div>
        <div className="row4">
          <Form.Item
            className="form-item"
            name="realization_date"
            rules={[requiredRule]}
          >
            <FormField
              type="date"
              placeholder="* Datum provedení"
            />
          </Form.Item>
          <Form.Item
            className="form-item"
            name="realization_time"
            rules={[requiredRule]}
          >
            <FormField
              type="time"
              placeholder="* Čas provedení"
            />
          </Form.Item>
          <Form.Item
            className="form-item"
            name="note"
          >
            <FormField
              type="text"
              placeholder="Poznámka"
            />
          </Form.Item>
          <Form.Item
            className="form-item"
            name="laboratory"
            rules={[requiredRule]}
          >
            <FormField
              type="select"
              placeholder="* Přidělená laboratoř"
              options={getOptions(tableOptions.laboratory)}
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default EditOrderForm;
