const measured_value_col = (order) => ({
  Header: `Dílčí hodnota #${order}`,
  accessor: `measured_value_${order}`,
  minWidth: 190,
  isRequired: false,
});

const units_col = (order) => ({
  Header: `Jednotky #${order}`,
  accessor: `units_${order}`,
  minWidth: 130,
  isRequired: false,
});

const extraMeasuredValues = {
  measured_value_1: measured_value_col(1),
  units_1: units_col(1),
  measured_value_2: measured_value_col(2),
  units_2: units_col(2),
  measured_value_3: {
    Header: `Četnost celkem`,
    accessor: `measured_value_3`,
    minWidth: 150,
    isRequired: false,
  },
  units_3: {
    Header: `Počet nevyhovujících`,
    accessor: `units_3`,
    minWidth: 200,
    isRequired: false,
  },
};

//
export const columnsConfigMap = {
  test_number: {
    Header: '#',
    accessor: 'test_number',
    show_fixed: true,
    isReadOnly: true,
    minWidth: 50,
  },
  contractor: {
    Header: 'Objednatel',
    accessor: 'contractor',
    show_fixed: true,
    isRequired: true,
    minWidth: 150,
  },
  laboratory: {
    Header: 'Provedl (AZL, OZ)',
    accessor: 'laboratory',
    show_fixed: true,
    isRequired: true,
    isReadOnly: false,
    minWidth: 150,
  },
  object_label: {
    Header: 'Stavební objekt',
    accessor: 'object_label',
    show_fixed: false,
    isRequired: true,
    isReadOnly: true,
    minWidth: 150,
  },
  col_construction_phase: {
    Header: 'Fáze výstavby',
    accessor: 'col_construction_phase',
    show_fixed: false,
    isReadOnly: true,
    minWidth: 180,
  },
  col_construction_part: {
    Header: 'Konstrukční celek',
    accessor: 'col_construction_part',
    show_fixed: false,
    isReadOnly: true,
    minWidth: 180,
  },
  construction_part: {
    Header: 'Technologický celek',
    accessor: 'construction_part',
    show_fixed: false,
    isRequired: true,
    isReadOnly: true,
    minWidth: 180,
  },
  // col_technology_unit: {
  //   Header: 'Technologický celek',
  //   accessor: 'col_technology_unit',
  //   show_fixed: false,
  //   isReadOnly: true,
  //   minWidth: 180,
  // },
  technology_part: {
    Header: 'Část technologického celku',
    accessor: 'technology_part',
    show_fixed: false,
    isRequired: false,
    minWidth: 220,
  },
  construction_part_specification: {
    Header: 'Specifikace zk.místa, druh materiálu, asfaltové směsi',
    accessor: 'construction_part_specification',
    show_fixed: false,
    isRequired: true,
    minWidth: 230,
  },
  stationing: {
    Header: 'Staničení (km)',
    accessor: 'stationing',
    show_fixed: false,
    // isRequired: true,
    isReadOnly: true,
    maxLength: 24,
  },
  from_axis: {
    Header: 'Od osy (L,P,m), (x/y/z), (vrstva)',
    accessor: 'from_axis',
    show_fixed: false,
    minWidth: 240,
  },
  date: {
    Header: 'Datum zkoušky / odběru',
    accessor: 'date',
    show_fixed: false,
    isRequired: true,
    isReadOnly: false,
    minWidth: 210,
  },
  construction_test: {
    Header: 'Typ zkoušky',
    accessor: 'construction_test',
    show_fixed: false,
    isRequired: true,
    isReadOnly: true,
    minWidth: 300,
  },
  construction_test_group: {
    Header: 'Skupina zkoušek',
    accessor: 'construction_test_group',
  },
  delivery_note_num: {
    Header: 'Číslo dodacího listu',
    accessor: 'delivery_note_num',
    show_fixed: false,
    minWidth: 180,
  },
  protocol_number: {
    Header: 'Číslo protokolu',
    accessor: 'protocol_number',
    show_fixed: false,
    isRequired: true,
    minWidth: 180,
  },
  measured_value: {
    Header: 'Hlavní naměřená hodnota',
    accessor: 'measured_value',
    show_fixed: false,
    isRequired: true,
    minWidth: 220,
  },
  units: {
    Header: 'Jednotky',
    accessor: 'units',
    show_fixed: false,
    isRequired: true,
    minWidth: 115,
  },
  ...extraMeasuredValues,
  desired_value: {
    Header: 'Požadováná hodnota',
    accessor: 'desired_value',
    show_fixed: false,
    isRequired: true,
    minWidth: 190,
  },
  evaluation: {
    Header: 'Závěr V/N',
    accessor: 'evaluation',
    show_fixed: false,
    isRequired: true,
    minWidth: 190,
  },
  test_retake: {
    Header: 'Oprava zkoušky',
    accessor: 'test_retake',
    show_fixed: false,
    isReadOnly: true,
    minWidth: 135,
  },
  note: {
    Header: 'Poznámka',
    accessor: 'note',
    show_fixed: false,
  },
  attachments: {
    Header: 'Příloha',
    accessor: 'attachments',
    headerClassName: 'file-upload-head',
    isRequired: true,
    show_fixed: false,
    minWidth: 200,
  },
  protocols: {
    Header: 'Protokoly',
    accessor: 'protocols',
    headerClassName: 'file-upload-head',
    show_fixed: false,
    minWidth: 200,
  },
  confirmation_time: {
    Header: 'Datum odevzdání',
    accessor: 'confirmation_time',
    show_fixed: false,
    isReadOnly: true,
    minWidth: 200,
  },
  confirmation: {
    Header: '✓',
    accessor: 'confirmation',
    show_fixed: false,
    isReadOnly: true,
    width: 70,
  },
  activity_id: {
    Header: 'Číselné ozn.',
    accessor: 'activity_id',
    isReadOnly: true,
    show_fixed: false,
    width: 115,
  },
  author: {
    Header: 'Zkoušku provedl',
    accessor: 'author',
    show_fixed: false,
    isReadOnly: true,
    minWidth: 190,
  },
  edit_entry: {
    Header: '',
    accessor: 'edit_entry',
    show_fixed: true,
    isReadOnly: true,
    width: 65,
  },
  from_sample_id: {
    Header: 'Ze vzorku',
    accessor: 'from_sample_id',
    show_fixed: false,
    isReadOnly: true,
    width: 115,
  },
  gps_coordinates: {
    Header: 'Lokace',
    accessor: 'gps_coordinates',
    show_fixed: false,
    isReadOnly: true,
    minWidth: 100,
  },
};

// see RuleRoadTableDefaultOrder for updates
export const columnsDefaultOrder = [
  'edit_entry',
  'test_number',
  'contractor',
  'laboratory',
  'activity_id',
  'from_sample_id',
  'object_label',
  'col_construction_phase',
  'col_construction_part',
  'construction_part',
  'technology_part',
  'construction_part_specification',
  'stationing',
  'from_axis',
  'date',
  'construction_test',
  'delivery_note_num',
  'protocol_number',
  'measured_value',
  'units',
  'measured_value_1',
  'units_1',
  'measured_value_2',
  'units_2',
  'measured_value_3',
  'units_3',
  'desired_value',
  'evaluation',
  'note',
  'author',
  'confirmation_time',
  'attachments',
  'protocols',
  'confirmation',
  'gps_coordinates',
  'test_retake',
];
