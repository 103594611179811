import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { notification } from 'antd';

import { request } from '@utils';

const MAPPING = {
  t: "token",
  ct: "construction_test",
  c: "contractor",
  cn: "construction",
  pn: "protocol_number",
  fs: "from_sample",
  cps: "construction_part_specification",
  ol: "object_label",
  tp: "technology_part",
  d: "date",
  mv: "measured_value",
  mv1: "measured_value_1",
  u: "units",
  u1: "units_1",
  u2: "units_2",
  a: "author",
  l: "laboratory",
  s: "stationing",
};

const QRImportPage = () => {
  const [params] = useSearchParams();

  useEffect(async () => {
    const tests = params.get('ct').split(',');
    for (const test of tests) {
      const payload = {
        'construction_test': test,
      };
      for (const param of params) {
        const [key, value] = param;
        const mappedKey = MAPPING[key];
        if (mappedKey !== 'construction_test') {
          payload[mappedKey] = value;
        }
      }
      try {
        const response = await request('/api/constructions/qr-import-data', {
          method: 'POST',
          data: payload,
        });
        notification.success({
          message: response.data,
          duration: 0,
          style: { whiteSpace: 'pre-line', width: '500px' },
        });
      } catch (error) {
        let message = 'Došlo k chybě';
        if (typeof error?.response?.data === 'string') {
          message = error?.response?.data;
        }
        notification.error({
          message,
          duration: 0,
          style: { whiteSpace: 'pre-line', width: '500px' },
        });
      }
    }
  }, []);

  return null;
};

export default QRImportPage;
