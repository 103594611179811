import { Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const SearchFilter = ({
  selectedKeys,
  setSelectedKeys,
  confirm,
}: any) => {
  const handleSearch = () => {
    confirm();
  }

  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <Input
        value={selectedKeys.length > 0 ? selectedKeys : ''}
        onChange={(event) => setSelectedKeys(event.target.value)}
        style={{ width: 230 }}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 8 }}>
        <Button
          onClick={() => {
            setSelectedKeys('');
            handleSearch();
          }}
          size="small"
        >
          Zrušit
        </Button>
        <Button
          type="primary"
          onClick={() => handleSearch()}
          icon={<SearchOutlined />}
          size="small"
        >
          Vyhledat
        </Button>
      </div>
    </div>
  );
};

export default SearchFilter;
