import { Component, Fragment } from 'react';
import Waypoint from 'react-waypoint';
import moment from 'moment';
import { notification, message, Button, Modal, Input, Card } from 'antd';
import {
  PlusOutlined,
  ExportOutlined,
  SettingOutlined,
  UploadOutlined,
  FileTextOutlined,
  DownloadOutlined,
} from '@ant-design/icons';

import {
  CloseButton,
  ExportFile,
  ExportTable,
  FileList,
  RoadTestsFileList,
  ResizableVirtualTable,
} from '@components';

import { useModal } from '@hooks';

import {
  mapOptionsToRoadtests,
  getFilterOptions,
  updateById,
  getFilterQuery,
  highlightTableRow,
  deepEqual,
  isPDF,
} from '@utils';

import getTableColumns from './getTableColumns';
import {
  getTables,
  getTableOptions,
  getTableRows,
  getMoreTableRows,
  getFilteredRows,
  getContractorDataSource,
  addConstructionEntry,
  saveActiveRowValues,
  createTestsFromSample,
  finishActiveRow,
  confirmProtocol,
  denyProtocol,
  importData,
  importProtocols,
} from './requests';

// roadstable domain
import AddRoadTestForm from './AddRoadTestForm';
import EditRoadTestForm from './EditRoadTestForm';
import { createExportFile, createExportPDFFile } from './requests';
import CreateTestsFromSample from './CreateTestsFromSample';

const SPECIAL_MASS_EDIT_KEYS = [
  'contractor',
  'construction_part_specification',
  'from_axis',
  'delivery_note_num',
  'protocol_number',
  'attachments',
];

class RoadTestsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      tableOptions: {},

      loadingTable: true,

      isFiltering: false,
      filteredData: [],

      isAddMode: false,
      isEditMode: false,
      isRetakeMode: false,

      isImportingData: false,
      isImportingProtocols: false,

      tableKey: 1,
    };

    this.moreRowsUrl = '';
    this.moreFilterRowsUrl = '';
    this.permittedUserActions = {};
    this.filters = {};
    this.testRetakeRowId = '';
    this.testRetakeInputValue = '';

    this.isSpecialEdit = false;
    this.remainingSpecialEdits = null;
    this.speciallyEditedCount = null;

    this.isMassEdit = false;

    // this.attachmentFilesToUpload = [];
    // this.multipleActiveRows = false;
    // this.selectedTableRow = undefined;
    // this.previousClassName = undefined;
  }

  componentDidMount() {
    this.requestTableData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ID !== this.props.ID) {
      this.setFiltersToNull();
      this.setModeToNull();
      this.requestTableData();
    }
  }

  resolveTableOptions(tableOptionsRaw) {
    return {
      ...tableOptionsRaw,
      units_1: tableOptionsRaw.units,
      units_2: tableOptionsRaw.units,
    };
  }

  requestTableData(callback) {
    const { ID } = this.props;
    this.setState({ loadingTable: true });

    return Promise.all([
      getTableRows(ID),
      getTableOptions(ID)
    ]).then((responses) => {
      const [tableData, tableOptionsRaw] = responses;
      const {
        next,
        permitted_actions,
        results,
        finished_count,
        specially_edited_count,
      } = tableData;

      this.permittedUserActions = permitted_actions;
      this.moreRowsUrl = next;
      this.finishedCount = finished_count;

      this.speciallyEditedCount = specially_edited_count;
      this.remainingSpecialEdits = Math.ceil(finished_count / 10) - specially_edited_count;

      let tableOptions = this.resolveTableOptions(tableOptionsRaw);
      tableOptions['possible_author'] = tableOptions['possible_author']
        .map((a) => ({
          id: a.id,
          name: `${a.last_name} ${a.first_name}`,
        }))
        .sort((a, b) => {
          // sort by alphabet
          const nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase();
          if (nameA < nameB) {
            return -1;
          } else if (nameA > nameB) {
            return 1;
          } else {
            return 0;
          }
        });
      const modifiedData = mapOptionsToRoadtests(results, tableOptions);

      this.activeEntry = null;
      this.isSpecialEdit = false;

      this.setState({
        tableData: modifiedData,
        tableOptions,
        loadingTable: false,
      }, () => {
        if (callback) callback();
      });
    });
  }

  loadMoreData = (isFiltered = false) => {
    if (
      (!isFiltered && !this.moreRowsUrl) ||
      (isFiltered && !this.moreFilterRowsUrl) ||
      this.isLoadingMoreData
    ) {
      return;
    }

    this.isLoadingMoreData = true;

    const onOk = ({ next, results }) => {
      this.isLoadingMoreData = false;
      if (!isFiltered) {
        this.moreRowsUrl = next;
        this.setState({
          tableData: this.state.tableData.concat(
            mapOptionsToRoadtests(results, this.state.tableOptions)
          ),
        });
      } else {
        this.moreFilterRowsUrl = next;
        this.setState({
          filteredData: this.state.filteredData.concat(
            mapOptionsToRoadtests(results, this.state.tableOptions)
          ),
        });
      }
    };

    const onErr = () => {
      this.isLoadingMoreData = false;
      message.error('Při načítání záznamů došlo k chybě');
    };

    if (!isFiltered) {
      getMoreTableRows(this.moreRowsUrl).then(onOk, onErr);
    } else {
      getMoreTableRows(this.moreFilterRowsUrl).then(onOk, onErr);
    }
  };

  fetchFilterData = () => {
    const query = getFilterQuery(this.filters);
    getFilteredRows(this.props.ID, query).then(({ next, results }) => {
      const newFilteredData = mapOptionsToRoadtests(results, this.state.tableOptions);
      this.moreFilterRowsUrl = next;
      this.setState({
        filteredData: newFilteredData,
        isFiltering: true,
      });
    });
  }

  setModeToNull = (callback = () => {}) => {
    this.activeEntry = null;
    this.addFormInitialValues = null;
    this.isSpecialEdit = false;
    this.isMassEdit = false;
    this.testRetakeRowId = null;
    this.testRetakeInputValue = null;
    this.protocolDenialReason = null;

    this.setState({
      isAddMode: false,
      isEditMode: false,
      isRetakeMode: false,
    }, callback);
  };

  setFiltersToNull() {
    this.filters = {};
    this.setState({
      ...this.state,
      tableKey: this.state.tableKey + 1,
      filteredData: [],
      isFiltering: false,
    });
  }

  canUploadProtocol() {
    const { protocols } = this.activeEntry;
    const latestProtocol = protocols[protocols.length - 1];

    if (
      !latestProtocol ||
      latestProtocol.denied ||
      (latestProtocol.confirmed_by_stavbyvedouci && latestProtocol.confirmed_by_kvalitar)
    ) {
      return true;
    } else {
      return false;
    }
  }

  hasFinalProtocol() {
    if (!this.props.allowProtocolCirculation) {
      return true;
    } else {
      const { protocols } = this.activeEntry;
      if (!protocols || protocols.length === 0) return false;

      const finalProtocol = protocols.filter((_) => _.version_number === 2)[0];
      if (!finalProtocol) return false;
      else return true;
    }
  }

  onCreateTestsFromSampleSubmit = (sampleId, rows) => {
    this.props.closeSampleTestsModal();

    let construction_tests = [];

    rows.forEach(({ group, test, tests }) => {
      if (group) {
        construction_tests = [...construction_tests, ...tests.map(_ => _.id)];
      } else {
        construction_tests.push(test);
      }
    });

    const payload = {
      sampling: sampleId,
      construction_tests: construction_tests,
    };

    createTestsFromSample(this.props.ID, payload)
      .then(() => this.requestTableData());
  };

  openAttachmentModal = (row) => {
    this.props.openAttachmentModal('Přílohy', {
      files: (row?.attachments || []).map((file) => ({
        ...file,
        name: !file.protocol_id ? file.name : (
          file.version_number === 1 ? (
            'Protokol - pracovní verze'
          ) : 'Protokol - finální verze'
        ),
        url: file.file,
      })),
      readOnly: true,
    });
  };

  openProtocolModal = (row) => {
    this.props.openAttachmentModal('Protokoly', {
      files: (row?.protocols || []).map((file) => ({
        ...file,
        name: !file.protocol_id ? file.name : (
          file.version_number === 1 ? (
            'Protokol - pracovní verze'
          ) : 'Protokol - finální verze'
        ),
        url: file.file,
      })),
      readOnly: true,
    });
  };

  openExportModal = () => {
    this.props.openExportModal('Uložit report', {
      allowExcel: true,
      onExport: (type: string) => {
        const query = getFilterQuery(this.filters);
        return createExportFile(this.props.ID, query, type)
          .then((response) => response.data);
      },
    });
  };

  openExportPDFModal = () => {
    this.props.openExportPDFModal('Uložit PDF report na základě filtrů', {
      buttonText: 'Vytvořit PDF soubor',
      onExport: () => {
        const query = getFilterQuery(this.filters);
        return createExportPDFFile(this.props.ID, query)
          .then((response) => response.data);
      },
    });
  };

  openSampleTestsModal = (sample) => {
    const { tableOptions } = this.state;
    this.props.openSampleTestsModal('Vytvořit zkoušky ze vzorku ' + sample.activity_id, {
      sample,
      test_types: tableOptions.test_type,
      construction_test_groups: tableOptions.construction_test_group.filter(({ zkouska_ze_vzorku }) => zkouska_ze_vzorku),
      construction_tests: tableOptions.construction_test,
      onSubmit: this.onCreateTestsFromSampleSubmit,
    });
  };

  // layerNumberValidation = (value) => {
  //   if (value <= 100 && value > 0) return '';
  //   else return 'Musí být mezi 1 - 100';
  // };

  setActiveEntry = (row, isSpecialEdit) => {
    this.setModeToNull(() => {
      this.activeEntry = row;
      this.isSpecialEdit = isSpecialEdit;
      this.isMassEdit = false;
      if (row.from_sample || row.from_group) {
        this.isMassEdit = true;
        // Check if row was mass edited before
        SPECIAL_MASS_EDIT_KEYS.forEach((key) => {
          if (key === 'attachments') {
            if (this.activeEntry[key].length > 0) {
              this.isMassEdit = false;
            }
          } else if (this.activeEntry[key]) {
            this.isMassEdit = false;
          }
        });
      }

      this.setState({ isEditMode: true });
    });
  };

  testRetakeInit = (row) => {
    this.setModeToNull(() => {
      this.testRetakeRowId = row.id;
      this.setState({ isRetakeMode: true });
    });
  };

  viewInitialTest = (row) => {
    const testNum = row.fixed_parent_activity_id ? row.fixed_parent_activity_id : null;

    this.filters = {
      ...this.filters,
      activity_id: testNum,
    };

    const query = getFilterQuery(this.filters);
    getFilteredRows(this.props.ID, query).then(({ results }) => {
      const newFilteredData = mapOptionsToRoadtests(results, this.state.tableOptions);
      this.setState({
        filteredData: [row, ...newFilteredData],
        isFiltering: true,
      });
    });
  }

  renderConfigBtn() {
    return (
      <Button
        type="primary"
        shape="circle"
        size="large"
        icon={<SettingOutlined />}
        style={{ margin: 10, height: 54, width: 54 }}
        onClick={() => this.props.onActivateConfigMode()}
        title="Nastavit tabulku"
      />
    );
  }

  renderExportPDFBtn() {
    return (
      <Button
        type="primary"
        shape="circle"
        size="large"
        icon={<span style={{ fontSize: 16 }}>PDF</span>}
        style={{ margin: 10, height: 54, width: 54 }}
        onClick={() => this.openExportPDFModal()}
        title="Uložit PDF report na základě filtrů"
      />
    );
  }

  renderExportBtn() {
    return (
      <Button
        type="primary"
        shape="circle"
        size="large"
        icon={<ExportOutlined />}
        style={{ margin: 10, height: 54, width: 54 }}
        onClick={() => this.openExportModal()}
        title="Uložit report"
      />
    );
  }

  renderImportDataBtn() {
    return (
      <Fragment>
        <input
          type="file"
          id="import-data-input"
          style={{ display: 'none' }}
          onChange={(event) => {
            const file = event.target.files[0];
            // Store using JSON
            const filename = JSON.parse(JSON.stringify(file.name));

            this.setState({ isImportingData: true }, () => {
              importData(this.props.ID, file)
                .then((response) => {
                  document.getElementById('import-data-input').value = '';
                  notification.success({
                    duration: 20,
                    message: 'Importování záznamů proběhlo v pořádku',
                    description: `Záznam ${filename} byl importován úspěšně`,
                  });

                  this.setState({ isImportingData: false }, () => {
                    this.requestTableData();
                  });
                })
                .catch((error) => {
                  document.getElementById('import-data-input').value = '';
                  const { data } = error.response;

                  notification.error({
                    duration: 20,
                    message: 'Při importování záznamů došlo k chybě',
                    description: data,
                  });

                  this.setState({ isImportingData: false });
                });
            });
          }}
        />
        <Button
          type="primary"
          shape="circle"
          size="large"
          icon={<UploadOutlined />}
          loading={this.state.isImportingData}
          style={{ margin: 10, height: 54, width: 54 }}
          onClick={() => {
            if (!this.state.isImportingData && !this.state.isImportingProtocols) {
              document.getElementById('import-data-input').click();
            }
          }}
          title="Importovat záznamy"
        />
      </Fragment>
    );
  }

  renderImportProtocolsBtn() {
    return (
      <Fragment>
        <input
          type="file"
          multiple="multiple"
          id="import-protocols-input"
          style={{ display: 'none' }}
          onChange={(event) => {
            const files = event.target.files;

            this.setState({ isImportingProtocols: true }, () => {
              importProtocols(this.props.ID, files)
                .then((responses) => {
                  document.getElementById('import-protocols-input').value = '';

                  let successful_imports = 0;
                  let unsuccessful_import_names = [];

                  responses.forEach((response) => {
                    const { status, name } = response.data;
                    if (status === 1) {
                      successful_imports++;
                    } else {
                      unsuccessful_import_names.push(name);
                    }
                  });

                  if (unsuccessful_import_names.length === 0) {
                    notification.success({
                      duration: 20,
                      message: 'Všechny protokoly byly importovány úspěšně',
                      description: `Počet importovaných protokolů je ${successful_imports}`,
                    });
                  } else if (successful_imports === 0) {
                    notification.error({
                      duration: 20,
                      message: 'Žádný z protokolů nebyl importován',
                      description: 'Pravděpodobně neexistují záznamy s daným číslem protokolu',
                    });
                  } else {
                    let text = '';
                    unsuccessful_import_names.forEach((name, index) => {
                      if (index) text += ', ';
                      text += name;
                    });
                    notification.warning({
                      duration: 20,
                      message: 'Protokoly byly částečně importovány',
                      description: `Nepodařilo se importovat protokoly ${text}. Ostatní (celkem ${successful_imports}) byly importovány úspěšně`,
                    });
                  }

                  this.setState({ isImportingProtocols: false }, () => {
                    if (successful_imports > 0) {
                      this.requestTableData();
                    }
                  });
                })
                .catch((error) => {
                  document.getElementById('import-protocols-input').value = '';
                  const { data } = error.response;

                  notification.error({
                    duration: 20,
                    message: 'Při importování protokolů došlo k chybě',
                    description: data,
                  });

                  this.setState({ isImportingProtocols: false });
                });
            });
          }}
        />

        <Button
          type="primary"
          shape="circle"
          size="large"
          icon={<FileTextOutlined />}
          style={{ margin: 10, height: 54, width: 54 }}
          onClick={() => {
            if (!this.state.isImporting && !this.state.isImportingProtocols) {
              document.getElementById('import-protocols-input').click();
            }
          }}
          title="Importovat protokoly"
        />
      </Fragment>
    );
  }

  renderAddBtn() {
    if (this.permittedUserActions.can_create_entry) {
      return (
        <Button
          type="primary"
          shape="circle"
          size="large"
          icon={<PlusOutlined />}
          style={{ margin: '10px 20px 10px 10px', height: 54, width: 54 }}
          onClick={() => this.setState({ isAddMode: true })}
          title="Přidat nový řádek"
        />
      );
    }
  }

  onFinishTestRetake = () => {
    if (this.testRetakeInputValue === '') {
      return message.error('Je nutné zadat hodnotu');
    }

    let parentRowId = this.state.tableData.filter(
      (row) => String(row.test_number) === String(this.testRetakeInputValue)
    );
    if (parentRowId.length > 0) {
      parentRowId = parentRowId[0].id;
    } else {
      return message.error('Záznam nebyl nalezen');
    }

    saveActiveRowValues(this.props.ID, parentRowId, { fixed_parent: this.testRetakeRowId })
      .then(() => {
        this.requestTableData().then(() => {
          this.setModeToNull();
        });
      });
  };

  onCancelTestRetake = () => {
    this.setModeToNull();
  };

  updateRoadTestData = (data, updateActiveEntry = true) => {
    const updatedRoadTestData = mapOptionsToRoadtests(
      [data],
      this.state.tableOptions,
    )[0];
    const tableData = updateById(
      this.state.tableData,
      updatedRoadTestData.id,
      updatedRoadTestData
    );
    const filteredData = updateById(
      this.state.filteredData,
      updatedRoadTestData.id,
      updatedRoadTestData,
    );
    if (updateActiveEntry) {
      this.activeEntry = updatedRoadTestData;
    }
    this.setState({ tableData, filteredData });
    return updatedRoadTestData;
  }

  onAddFormSubmit = (fields) => {
    return addConstructionEntry(this.props.ID, fields)
      .then(() => {
        localStorage.setItem('lastEntryFields', JSON.stringify(fields));
        this.requestTableData().then(() => {
          this.setModeToNull();
          message.success('Záznam byl vytvořen');
        });
      })
      .catch((error) => {
        console.error(error);
        message.error((
          error?.response?.data?.message ||
          'Při vytváření záznamu došlo k chybě'
        ));
      });
  };

  onEditFormSave = (fields) => {
    if (this.isSpecialEdit && !this.activeEntry.specially_edited) {
      this.remainingSpecialEdits -= 1;
      this.speciallyEditedCount += 1;
      this.activeEntry.specially_edited = true;
    }

    return saveActiveRowValues(
      this.props.ID,
      this.activeEntry.id,
      fields,
      { isMassEdit: this.isMassEdit },
    ).then(({ data }) => {
      message.success('Změny byly uloženy');

      const updatedRoadTestData = mapOptionsToRoadtests(
        [data],
        this.state.tableOptions,
      )[0];
      let tableData = updateById(
        this.state.tableData,
        updatedRoadTestData.id,
        updatedRoadTestData
      );
      let filteredData = updateById(
        this.state.filteredData,
        updatedRoadTestData.id,
        updatedRoadTestData
      );

      if (this.isMassEdit) {
        [
          ...(this.activeEntry.from_sample ? (
            this.state.tableData.filter((_) => {
              return _.from_sample === this.activeEntry.from_sample && _.id !== this.activeEntry.id;
            })
          ) : []),
          ...(this.activeEntry.from_group ? (
            this.state.tableData.filter((_) => {
              return _.from_group === this.activeEntry.from_group && _.id !== this.activeEntry.id;
            })
          ) : []),
        ].forEach((test: any) => {
          SPECIAL_MASS_EDIT_KEYS.forEach((key: string) => {
            test[key] = updatedRoadTestData[key];
            tableData = updateById(
              tableData,
              test.id,
              test
            );
          });
        });

        [
          ...(this.activeEntry.from_sample ? (
            this.state.filteredData.filter((_) => {
              return _.from_sample === this.activeEntry.from_sample && _.id !== this.activeEntry.id;
            })
          ) : []),
          ...(this.activeEntry.from_group ? (
            this.state.filteredData.filter((_) => {
              return _.from_group === this.activeEntry.from_group && _.id !== this.activeEntry.id;
            })
          ) : []),
        ].forEach((test: any) => {
          SPECIAL_MASS_EDIT_KEYS.forEach((key: string) => {
            test[key] = updatedRoadTestData[key];
            filteredData = updateById(
              filteredData,
              test.id,
              test
            );
          });
        });
      }

      this.activeEntry = updatedRoadTestData;
      this.setState({ tableData, filteredData });
    }).catch(() => {
      message.error('Při editaci záznamu došlo k chybě');
    });
  }

  onEditFormFinish = (fields) => {
    if (!this.hasFinalProtocol()) {
      return message.error('Zkouška nebyla uzavřena, je nutné přidat finální protokol');
    }

    const onOk = ({ data }) => {
      this.finishedCount += 1;
      const { finishedCount, speciallyEditedCount } = this;
      this.remainingSpecialEdits = Math.ceil(finishedCount / 10) - speciallyEditedCount;

      const updatedRoadTestData = mapOptionsToRoadtests(
        [data],
        this.state.tableOptions,
      )[0];
      const tableData = updateById(
        this.state.tableData,
        updatedRoadTestData.id,
        updatedRoadTestData
      );

      message.success('Uzavření zkoušky proběhlo v pořádku');
      this.setState({ tableData }, () => {
        this.setModeToNull();
      });
    };

    const onErr = () => {
      message.error('Při uzavírání zkoušky došlo k chybě');
    };

    finishActiveRow(this.props.ID, this.activeEntry.id, fields)
      .then(onOk, onErr);
  }

  onConfirmProtocol(protocol_id) {
    confirmProtocol(this.props.ID, protocol_id).then(({ data }) => {
      const protocols = updateById(this.activeEntry.protocols, data.id, data);
      const newActiveRow = { ...this.activeEntry, protocols };
      this.updateRoadTestData(newActiveRow);
      this.protocolDenialReason = '';
    });
  }

  onDenyProtocol(protocol_id) {
    if (!this.protocolDenialReason) {
      message.error('Prosím vyplňte důvod zamítnutí');
    } else {
      denyProtocol(this.props.ID, protocol_id, this.protocolDenialReason)
        .then(({ data }) => {
          const protocols = updateById(this.activeEntry.protocols, data.id, data);
          const newActiveRow = { ...this.activeEntry, protocols };
          this.updateRoadTestData(newActiveRow);
          this.protocolDenialReason = '';
        });
    }
  }

  renderAddRoadTestForm() {
    const { tableOptions } = this.state;
    return (
      <AddRoadTestForm
        freeMode={this.props.freeMode}
        tableOptions={tableOptions}
        onFormSubmit={this.onAddFormSubmit}
        lastRoadTestFields={JSON.parse(localStorage.getItem('lastEntryFields'))}
        setModeToNull={() => this.setModeToNull()}
      />
    );
  }

  renderProtocols() {
    const protocols = this.activeEntry.protocols;
    if (!protocols) return null;

    const {
      can_confirm_stavbyvedouci_protocols,
      can_confirm_kvalitar_protocols,
      can_upload_protocols,
    } = this.permittedUserActions;

    return (
      <Fragment>
        <div className="protocols-container">
          <Card bodyStyle={{ paddingTop: 8, paddingBottom: 8 }}>
            <div className="protocols-box">
              <div style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                flexDirection: 'row',
                height: '100%',
                width: '100%',
              }} >
                {protocols.length === 0 ? (
                  <p className="no-protocols-text">Zkouška zatím neobsahuje žádné protokoly</p>
                ) : (
                  <Fragment>
                    {protocols.map(
                      (
                        {
                          protocol_id,
                          file,
                          name,
                          version_number,
                          upload_time,
                          confirmed_by_stavbyvedouci,
                          confirmed_by_kvalitar,
                          denied,
                          denial_time,
                          denial_reason,
                        },
                        index
                      ) => {
                        return (
                          <div
                            key={protocol_id}
                            style={{
                              borderBottom: index !== protocols.length - 1 && '1px solid #ddd',
                              width: '100%',
                            }}
                          >
                            <a href={file} target="_blank">
                              <div className="protocol-name-container" style={{ display: 'flex', alignItems: 'center' }}>
                                <DownloadOutlined style={{ cursor: 'pointer' }} className="theme-color" />
                                <h3 className="protocol-name">
                                  {version_number === 1
                                    ? 'Protokol - pracovní verze'
                                    : 'Protokol - finální verze'}
                                </h3>
                              </div>
                            </a>
                            {!denied &&
                              version_number === 1 &&
                              ((!confirmed_by_stavbyvedouci &&
                                can_confirm_stavbyvedouci_protocols) ||
                                (!confirmed_by_kvalitar && can_confirm_kvalitar_protocols)) && (
                                <div>
                                  <Button
                                    className="green-button"
                                    type="primary"
                                    shape="round"
                                    onClick={() => this.onConfirmProtocol(protocol_id)}
                                  >
                                    Potvrdit
                                  </Button>
                                  <Button
                                    type="primary"
                                    shape="round"
                                    onClick={() => this.onDenyProtocol(protocol_id)}
                                    style={{ marginLeft: 20 }}
                                    danger
                                  >
                                    Zamítnout
                                  </Button>
                                  <textarea
                                    className="protocol-denial-textarea"
                                    placeholder="Důvod zamítnutí"
                                    rows="4"
                                    onChange={(event) => {
                                      const { value } = event.target;
                                      this.protocolDenialReason = value;
                                    }}
                                  />
                                </div>
                              )}
                            {confirmed_by_stavbyvedouci && (
                              <p className="protocol-status green-text">
                                Protokol byl potvrzen stavbyvedoucím{' '}
                                {confirmed_by_kvalitar && ' a kvalitářem'}
                              </p>
                            )}
                            {denied && (
                              <Fragment>
                                <p className="protocol-status red-text">
                                  Protokol byl zamítnut z následujícího důvodu:
                                </p>
                                <p className="protocol-denial-reason">
                                  {denial_reason || 'Důvod neuveden'}
                                </p>
                              </Fragment>
                            )}
                          </div>
                        );
                      }
                    )}
                  </Fragment>
                )}
                {can_upload_protocols && this.canUploadProtocol() && !this.hasFinalProtocol() && (
                  <div style={{ marginBottom: 10 }}>
                    <RoadTestsFileList
                      files={[]}
                      listType="picture"
                      noItemRender
                      uploadContent={(
                        <Button icon={<UploadOutlined />}>
                          {(() => {
                            const lastProtocol = this.activeEntry.protocols[this.activeEntry.protocols.length - 1];
                            if (lastProtocol?.confirmed_by_stavbyvedouci && lastProtocol?.confirmed_by_kvalitar) {
                              return 'Nahrát finální protokol';
                            } else {
                              return 'Nahrát protokol';
                            }
                          })()}
                        </Button>
                      )}
                      onChange={(files: RoadTestsFile[]) => {
                        if (isPDF(files[0].name)) {
                          this.onEditFormSave({
                            add_protocols_ids: [
                              ...this.activeEntry.protocols.map((file: RoadTestsFile) => file.id),
                              ...files.map((file: RoadTestsFile) => file.id)
                            ],
                          });
                        } else {
                          message.error('Je nutné nahrát PDF soubor o maximální velikosti 700 MB');
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </Card>
        </div>
      </Fragment>
    );
  }

  renderEditForm() {
    if (!this.activeEntry) {
      return this.setModeToNull();
    }

    const editableColumns = this.props.activeColumns.filter((col) => {
      if (!this.props.freeMode) {
        return col !== 'laboratory' && col !== 'date';
      } else {
        return true;
      }
    });

    if (this.props.isAdmin) {
      editableColumns.push('esd_record');
    }

    return (
      <div style={{ padding: "1rem 0", margin: "0 auto", width: "90%", maxWidth: 1300 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <p style={{ paddingTop: 10 }}>
            Právě editujete {this.activeEntry['fixed_parent'] ? 'opravnou zkoušku' : 'zkoušku'}
            <strong> #{this.activeEntry.test_number}</strong> |
            <strong> {this.activeEntry.activity_id}</strong> |
            <strong> {this.activeEntry.object_label.name}</strong> |
            <strong> {this.activeEntry.construction_test.name}</strong>
          </p>

          <CloseButton
            tooltip="Zavřít editační okno"
            onClick={() => this.setModeToNull()}
          />
        </div>

        <EditRoadTestForm
          constructionId={this.props.ID}
          activeEntry={this.activeEntry}
          setModeToNull={this.setModeToNull}
          allowProtocolCirculation={this.props.allowProtocolCirculation}
          permittedUserActions={this.permittedUserActions}
          freeMode={this.props.freeMode}
          editableColumns={editableColumns}
          tableOptions={this.state.tableOptions}
          onFormSave={this.onEditFormSave}
          onFormFinish={this.onEditFormFinish}
          initialValues={{
            ...this.activeEntry,
            construction_part: this.activeEntry.construction_part?.id,
            technology_part: this.activeEntry.technology_part?.id,
            units: this.activeEntry.units?.id,
            units_1: this.activeEntry.units_1?.id,
            units_2: this.activeEntry.units_2?.id,
            evaluation: this.activeEntry.evaluation?.id,
            laboratory: this.activeEntry.laboratory?.id,
            author: typeof this.activeEntry.author === "number" ? (
              parseInt(this.activeEntry.author)
            ) : this.activeEntry.author,
          }}
        />

        {this.permittedUserActions.can_view_protocols &&
          this.props.allowProtocolCirculation &&
          this.renderProtocols()}
      </div>
    );
  }

  renderTestRetake() {
    return (
      <div style={{
        width: '80%',
        paddingTop: '30px',
        paddingBottom: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <span>Zadejte číslo řádku opravné zkoušky:</span>
        <Input
          placeholder="Číslo řádku"
          onChange={(event) => {
            this.testRetakeInputValue = event.target.value;
          }}
          style={{ width: 160, marginLeft: 10 }}
        />
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <Button
            className="green-button"
            type="primary"
            shape="round"
            style={{ margin: '0 10px 0 20px' }}
            onClick={() => this.onFinishTestRetake()}
          >
            Odeslat
          </Button>
          <Button
            shape="round"
            onClick={() => this.onCancelTestRetake()}
          >
            Zrušit
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { isAdmin } = this.props;
    const {
      isAddMode,
      isEditMode,
      isRetakeMode,
      tableData,
      tableOptions,
      loadingTable,
    } = this.state;

    return (
      <div style={{ width: '100%', overflow: 'hidden' }}>
        {isAddMode && this.renderAddRoadTestForm()}
        {isEditMode && this.renderEditForm()}
        {isRetakeMode && this.renderTestRetake()}

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {this.state.isFiltering ? (
            <Button
              type="primary"
              shape="round"
              style={{ marginLeft: 20 }}
              onClick={() => this.setFiltersToNull()}
            >
              Zrušit filtrování
            </Button>
          ) : <div />}

          <div style={{ display: 'flex' }}>
            {
              !this.state.isAddMode &&
              !this.state.isEditMode &&
              !this.state.isRetakeMode && (
                <Fragment>
                  {this.renderConfigBtn()}
                  {this.renderExportPDFBtn()}
                  {this.renderExportBtn()}
                  {isAdmin && this.renderImportDataBtn()}
                  {isAdmin && this.renderImportProtocolsBtn()}
                  {this.renderAddBtn()}
                </Fragment>
              )
            }
          </div>
        </div>

        {!loadingTable && (
          <ResizableVirtualTable
            key={this.state.tableKey}
            bordered
            pagination={false}
            scroll={{
              x: '100vw',
              y: isRetakeMode ?
                'calc(100vh - 250px)' :
                'calc(100vh - 204px)',
            }}
            dataSource={(
              this.state.isFiltering ? this.state.filteredData : this.state.tableData
            ).map((_ => ({ ..._, key: _.id })))}
            onChange={(_, filters: any) => {
              this.filters = filters;
              this.fetchFilterData();
            }}
            rowClassName={(row) => {
              const { id, is_active, evaluation, entry_type } = row;

              let className = `table-row-${id}`;

              if (this.activeEntry && this.activeEntry.id === id) {
                className += ' table-row-editing';
              } else {
                if (!is_active) {
                  if (evaluation?.name === 'Vyhovuje') {
                    className += ' table-row-green';
                  } else if (evaluation?.name === 'Nevyhovuje') {
                    className += ' table-row-red';
                  } else if (entry_type === 'odber_vzorku') {
                    className += ' table-row-green';
                  }
                }
              }

              return className;
            }}
            onRow={({ id }) => ({ onClick: event => highlightTableRow(event, id) })}
            columns={getTableColumns({
              isFiltering: this.state.isFiltering,
              moreRowsUrl: this.moreRowsUrl,
              moreFilterRowsUrl: this.moreFilterRowsUrl,
              tableOptions: this.state.tableOptions,
              permittedUserActions: this.permittedUserActions,
              isAdmin: this.props.isAdmin,
              tableData: this.state.tableData,
              filteredData: this.state.filteredData,
              loadMoreData: this.loadMoreData,

              setActiveEntry: this.setActiveEntry,
              remainingSpecialEdits: this.remainingSpecialEdits,
              testRetakeInit: this.testRetakeInit,
              viewInitialTest: this.viewInitialTest,
              openAttachmentModal: this.openAttachmentModal,
              openProtocolModal: this.openProtocolModal,
              openSampleTestsModal: this.openSampleTestsModal,
            }).filter((column) => {
              return !column.key || this.props.activeColumns.indexOf(column.key) > -1;
            }).filter((column) => {
              return column.key !== 'protocols' || (
                this.props.allowProtocolCirculation &&
                this.permittedUserActions.can_view_protocols
              );
            }).sort((a, b) => {
              if (!a.key || !b.key || a.fixed || b.fixed) return 0;
              const aIdx = this.props.activeColumns.indexOf(a.key);
              const bIdx = this.props.activeColumns.indexOf(b.key);
              return aIdx > bIdx ? 1 : -1;
            })}
          />
        )}

        {this.props.renderSampleTestsModal()}
        {this.props.renderExportPDFModal()}
        {this.props.renderExportModal()}
        {this.props.renderAttachmentModal()}
      </div>
    );
  }
}

export default (props) => {
  const [
    openAttachmentModal,
    closeAttachmentModal,
    renderAttachmentModal,
  ] = useModal(
    FileList,
    { width: 600 },
  );

  const [
    openSampleTestsModal,
    closeSampleTestsModal,
    renderSampleTestsModal,
  ] = useModal(
    CreateTestsFromSample,
    { width: 1000 },
  );

  const [
    openExportPDFModal,
    closeExportPDFModal,
    renderExportPDFModal,
  ] = useModal(
    ExportFile,
    { width: 400 },
  );

  const [
    openExportModal,
    closeExportModal,
    renderExportModal,
  ] = useModal(
    ExportTable,
    { width: 400 },
  );

  return <RoadTestsTable
    {...props}

    openAttachmentModal={openAttachmentModal}
    closeAttachmentModal={closeAttachmentModal}
    renderAttachmentModal={renderAttachmentModal}

    openSampleTestsModal={openSampleTestsModal}
    closeSampleTestsModal={closeSampleTestsModal}
    renderSampleTestsModal={renderSampleTestsModal}

    openExportPDFModal={openExportPDFModal}
    closeExportPDFModal={closeExportPDFModal}
    renderExportPDFModal={renderExportPDFModal}

    openExportModal={openExportModal}
    closeExportModal={closeExportModal}
    renderExportModal={renderExportModal}
  />;
}
