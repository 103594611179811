import Waypoint from 'react-waypoint';
import { Button } from 'antd';
import {
  EditFilled,
  PlusCircleFilled,
  CheckCircleFilled,
  CloseCircleFilled,
} from '@ant-design/icons';

import { SearchFilter, DateRangeFilter } from '@components';

import { dayMonthYear, getFilterOptions, roundDecimalOrReturnOriginal } from '@utils';

const renderSetActiveEntryButton = ({
  row,
  isSample,
  isAdmin,
  permittedUserActions,
  remainingSpecialEdits,
  setActiveEntry,
}: any) => {
  const { is_active, confirmation_time, specially_edited } = row;

  let canEditRow = false;
  let isAdminEdit = false;
  let isSpecialEdit = false;

  // Handle admin case while using 'can_edit_entry' permission
  if (isAdmin || permittedUserActions.can_edit_extra_fields) {
    canEditRow = true;

    if (!is_active) {
      isAdminEdit = true;
    }
  } else if (permittedUserActions.can_edit_entry) {
    canEditRow = is_active && !isSample;
  }

  // Maybe special edit is possible?
  if (!canEditRow) {
    const today = new Date().toDateString();
    const confirmationTime = new Date(confirmation_time).toDateString();
    if (today === confirmationTime) {
      // Already specially edited today or it's possible to specially edit
      if (
        specially_edited ||
        (remainingSpecialEdits > 0 && !is_active && permittedUserActions.can_edit_entry)
      ) {
        canEditRow = true;
        isSpecialEdit = true;
      }
    }
  }

  if (!canEditRow) {
    return null;
  }

  return (
    <Button
      type="text"
      shape="circle"
      size="small"
      icon={<EditFilled style={{ fontSize: 18 }} className="theme-color" />}
      style={{ opacity: (isAdminEdit || isSpecialEdit) ? 0.25 : 1 }}
      onClick={() => setActiveEntry(row, isSpecialEdit)}
      title="Editovat"
    />
  );
}

const renderCreateTestsFromSampleButton = ({
  row,
  permittedUserActions,
  openSampleTestsModal,
}: any) => {
  if (
    !permittedUserActions.can_create_tests_from_sample ||
    row.entry_type !== 'odber_vzorku'
  ) {
    return null;
  }

  let isAdminEdit = false;
  if (!row.is_active) {
    // if (this.props.isAdmin) {
    //   isAdminEdit = true;
    // } else {
    //   return null;
    // }
    isAdminEdit = true;
  }

  return (
    <Button
      type="text"
      shape="circle"
      size="small"
      icon={<PlusCircleFilled style={{ fontSize: 18 }} className="theme-color" />}
      style={{ opacity: !isAdminEdit ? 1 : 0.25 }}
      onClick={() => openSampleTestsModal(row)}
      title="Přidat zkoušky ze vzorku"
    />
  );
}

const renderTestRetakeButton = ({
  row,
  testRetakeInit,
  viewInitialTest,
}: any) => {
  let testNum = row.fixed_parent_activity_id ? row.fixed_parent_activity_id : null;

  return (
    <div style={{ width: "100%", justifyContent: "space-around", alignItems: "center", display: "flex" }}>
      {row.evaluation?.name !== 'Nevyhovuje' && testNum && (
        <div onClick={() => viewInitialTest(row)} style={{ cursor: 'pointer' }}>
          {testNum}
        </div>
      )}
      {row.evaluation?.name === 'Nevyhovuje' && !row.test_fix_exists && (
        <Button
          type="primary"
          shape="round"
          size="small"
          onClick={() => testRetakeInit(row)}
        >
          OZ
        </Button>
      )}
    </div>
  );
}

const renderEllipsis = (value: string) => {
  return (
    <div className="ellipsis" title={value || '-'}>
      {value || '-'}
    </div>
  );
};

const getColumns = ({
  isFiltering,
  moreRowsUrl,
  moreFilterRowsUrl,
  tableOptions,
  permittedUserActions,
  isAdmin,
  tableData,
  filteredData,
  loadMoreData,

  setActiveEntry,
  remainingSpecialEdits,
  testRetakeInit,
  viewInitialTest,
  openAttachmentModal,
  openProtocolModal,
  openSampleTestsModal,
}: any) => {
  return [
    {
      title: '',
      fixed: 'left',
      width: 65,
      render: (row: any) => {
        const isSample = row.entry_type === 'odber_vzorku';
        return (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            {row.specially_edited && <div className="special-edit-stripe" />}
            {renderSetActiveEntryButton({
              row,
              isSample,
              isAdmin,
              permittedUserActions,
              remainingSpecialEdits,
              setActiveEntry,
            })}
            {renderCreateTestsFromSampleButton({
              row,
              permittedUserActions,
              openSampleTestsModal,
            })}
          </div>
        );
      },
    },
    {
      fixed: 'left',
      width: 0,
      render: (_: any, __: any, index: number) => {
        if (
          !isFiltering &&
          moreRowsUrl && (
            (index + 40) === tableData.length ||
            (index + 1) === tableData.length
          )
        ) {
          return <Waypoint onEnter={() => loadMoreData()} />;
        }

        if (
          isFiltering &&
          moreFilterRowsUrl && (
            (index + 40) === filteredData.length ||
            (index + 1) === filteredData.length
          )
        ) {
          return <Waypoint onEnter={() => loadMoreData(true)} />;
        }
      },
    },
    {
      title: '#',
      key: 'test_number',
      dataIndex: 'test_number',
      fixed: 'left',
      width: 50,
    },
    {
      title: 'Objednatel',
      key: 'contractor',
      dataIndex: 'contractor',
      fixed: 'left',
      width: 180,
      render: renderEllipsis,
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: 'Provedl (AZL, OZ)',
      key: 'laboratory',
      dataIndex: ['laboratory', 'name'],
      fixed: 'left',
      width: 180,
      render: renderEllipsis,
      filters: getFilterOptions(tableOptions.laboratory),
      filterSearch: true,
    },
    {
      title: 'Stavební objekt',
      key: 'object_label',
      dataIndex: ['object_label', 'name'],
      width: 150,
      render: (object_label_name: string, row: any) => (
        <div className="ellipsis" title={row.object_label?.description || '-'}>
          {object_label_name || '-'}
        </div>
      ),
      filters: getFilterOptions(tableOptions.object_label),
      filterSearch: true,
    },
    {
      title: 'Fáze výstavby',
      key: 'col_construction_phase',
      dataIndex: 'col_construction_phase',
      width: 180,
      render: renderEllipsis,
    },
    {
      title: 'Konstrukční celek',
      key: 'col_construction_part',
      dataIndex: 'col_construction_part',
      width: 180,
      render: renderEllipsis,
    },
    {
      title: 'Technologický celek',
      key: 'construction_part',
      dataIndex: 'construction_part',
      width: 180,
      render: (construction_part: any) => {
        let value = construction_part;
        if (typeof value !== 'string') {
          value = construction_part?.short_name || construction_part?.name;
        }
        return (
          <div className="ellipsis" title={value || '-'}>
            {value || '-'}
          </div>
        );
      },
      filters: getFilterOptions(tableOptions.construction_part, 'select_name'),
      filterSearch: true,
    },
    {
      title: 'Část technologického celku',
      key: 'technology_part',
      dataIndex: ['technology_part', 'name'],
      width: 220,
      render: renderEllipsis,
      filters: getFilterOptions(tableOptions.technology_part),
      filterSearch: true,
    },
    {
      title: (
        <div className="ellipsis" title="Specifikace zk.místa, druh materiálu, asfaltové směsi">
          Specifikace zk.místa, druh mat...
        </div>
      ),
      key: 'construction_part_specification',
      dataIndex: 'construction_part_specification',
      width: 230,
      render: renderEllipsis,
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: 'Staničení (km)',
      key: 'stationing',
      dataIndex: 'stationing',
      width: 200,
      render: renderEllipsis,
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: 'Od osy (L,P,m), (x/y/z), (vrstva)',
      key: 'from_axis',
      dataIndex: 'from_axis',
      width: 240,
      render: renderEllipsis,
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: 'Datum zkoušky / odběru',
      key: 'date',
      dataIndex: 'date',
      width: 210,
      render: (date: string) => dayMonthYear(date),
      filterDropdown: (props: any) => <DateRangeFilter {...props} />,
    },
    {
      title: 'Typ zkoušky',
      dataIndex: ['construction_test', 'name'],
      key: 'construction_test',
      width: 380,
      render: (construction_test_name: string, row: any) => (
        <div className="ellipsis" title={row.construction_test?.description || '-'}>
          {construction_test_name || '-'}
        </div>
      ),
      filters: getFilterOptions(tableOptions.construction_test),
      filterSearch: true,
    },
    {
      title: 'Číslo dodacího listu',
      key: 'delivery_note_num',
      dataIndex: 'delivery_note_num',
      width: 180,
      render: renderEllipsis,
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: 'Číslo protokolu',
      key: 'protocol_number',
      dataIndex: 'protocol_number',
      width: 180,
      render: renderEllipsis,
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: 'Hlavní naměřená hodnota',
      key: 'measured_value',
      dataIndex: 'measured_value',
      width: 220,
      render: (value?: string) => renderEllipsis(roundDecimalOrReturnOriginal(value)),
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: 'Jednotky',
      key: 'units',
      dataIndex: ['units', 'name'],
      width: 115,
      render: renderEllipsis,
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: `Dílčí hodnota #1`,
      key: `measured_value_1`,
      dataIndex: `measured_value_1`,
      width: 190,
      render: (value?: string) => renderEllipsis(roundDecimalOrReturnOriginal(value)),
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: `Jednotky #1`,
      key: `units_1`,
      dataIndex: [`units_1`, 'name'],
      width: 130,
      render: renderEllipsis,
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: `Dílčí hodnota #2`,
      key: `measured_value_2`,
      dataIndex: `measured_value_2`,
      width: 190,
      render: (value?: string) => renderEllipsis(roundDecimalOrReturnOriginal(value)),
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: `Jednotky #2`,
      key: `units_2`,
      dataIndex: [`units_2`, 'name'],
      width: 130,
      render: renderEllipsis,
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: `Četnost celkem`,
      key: `measured_value_3`,
      dataIndex: `measured_value_3`,
      width: 150,
      render: renderEllipsis,
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: `Počet nevyhovujících`,
      key: `units_3`,
      dataIndex: [`units_3`, 'name'],
      width: 200,
      render: renderEllipsis,
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: 'Požadováná hodnota',
      key: 'desired_value',
      dataIndex: 'desired_value',
      width: 190,
      render: renderEllipsis,
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: 'Závěr V/N',
      key: 'evaluation',
      dataIndex: ['evaluation', 'name'],
      render: renderEllipsis,
      width: 190,
    },
    {
      title: 'Oprava zkoušky',
      width: 135,
      render: (row: any) => renderTestRetakeButton({
        row,
        testRetakeInit,
        viewInitialTest,
      }),
    },
    {
      title: 'Poznámka',
      key: 'note',
      dataIndex: 'note',
      width: 200,
      render: renderEllipsis,
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: 'Přílohy',
      key: 'attachments',
      dataIndex: 'attachments',
      width: 120,
      render: (attachments: RoadTestsFile[], row: any) => (
        <Button
          className="no-highlight"
          type="text"
          shape="round"
          size="small"
          disabled={attachments.length === 0}
          onClick={() => openAttachmentModal(row)}
          style={{ fontWeight: 600 }}
        >
          <span className="no-highlight">
            {attachments.length + 'x Příloha'}
          </span>
        </Button>
      ),
    },
    {
      title: 'Protokoly',
      key: 'protocols',
      dataIndex: 'protocols',
      width: 120,
      render: (protocols: RoadTestsFile[], row: any) => (
        <Button
          className="no-highlight"
          type="text"
          shape="round"
          size="small"
          disabled={protocols.length === 0}
          onClick={() => openProtocolModal(row)}
          style={{ fontWeight: 600 }}
        >
          <span className="no-highlight">
            {protocols.length + 'x Příloha'}
          </span>
        </Button>
      ),
    },
    {
      title: 'Datum odevzdání',
      key: 'confirmation_time',
      dataIndex: 'confirmation_time',
      width: 200,
      render: (confirmation_time: string, row: any) => {
        const isSample = row.entry_type === 'odber_vzorku';
        if (row.is_active || isSample) return '';
        return dayMonthYear(confirmation_time);
      },
      filterDropdown: (props: any) => <DateRangeFilter {...props} />,
    },
    {
      title: '✓',
      key: 'confirmation',
      dataIndex: 'confirmation',
      width: 70,
      render: (confirmed: any, row: any) => {
        const isClosed = !!row.confirmation_time;
        if (row.is_active || !isClosed) {
          return '-';
        }
        if (confirmed === true) {
          return (
            <div style={{ display: 'inline-flex' }} title="V pořádku">
              <CheckCircleFilled style={{ color: '#4aac43', fontSize: 16 }} />
            </div>
          );
        } else if (confirmed === false) {
          return (
            <div style={{ display: 'inline-flex' }} title="Pozdě">
              <CloseCircleFilled style={{ color: 'darkred', fontSize: 16 }} />
            </div>
          );
        } else {
          return '-';
        }
      },
    },
    {
      title: 'Číselné ozn.',
      key: 'activity_id',
      dataIndex: 'activity_id',
      render: renderEllipsis,
      width: 115,
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: 'Zkoušku provedl',
      key: 'author',
      dataIndex: 'author_name',
      width: 190,
      render: renderEllipsis,
      filters: getFilterOptions(tableOptions.possible_author),
      filterSearch: true,
    },
    {
      title: 'Ze vzorku',
      key: 'from_sample_id',
      dataIndex: 'from_sample_id',
      width: 115,
    },
    {
      title: 'Lokace',
      key: 'gps_coordinates',
      dataIndex: 'gps_coordinates',
      width: 100,
      render: (gps_coordinates?: string) => {
        if (!gps_coordinates || gps_coordinates === '0.0,0.0') {
          return;
        }
        const [lat, long] = gps_coordinates.split(',');
        if (!lat || !long) {
          return;
        } else {
          return (
            <a
              style={{ textAlign: 'center' }}
              href={`http://www.google.com/maps/place/${lat},${long}`}
              target="_blank"
            >
              <svg viewBox="0 0 24 24" style={{
                display: "inline-block",
                color: "rgba(0, 0, 0, 0.87)",
                fill: "rgb(48, 63, 159)",
                height: "20px",
                width: "24px",
                userSelect: "none",
                transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
                cursor: "pointer",
                marginBottom: "-5px",
              }}>
                <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </a>
          );
        }
      },
    },
  ];
};

export default getColumns;
